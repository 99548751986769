import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './WeddingStore.css';

const WeddingStore = ({ bannerImage, loading }) => {
    const defaultImage = 'https://via.placeholder.com/150';
    const navigate = useNavigate();

    const handleBannerClick = (type_id, tag_type) => {
        navigate(`/latest-arrivals/${type_id}/${tag_type}`);
    };

    const settings = {
        items: 1,
        margin: 10,
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        dots: true,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
        },
    };

    return (
        <section className="wedding">
            {loading ? (
                <OwlCarousel className="banner-carousel owl-carousel owl-theme" {...settings}>
                    {[...Array(3)].map((_, index) => (
                        <div key={index} className="item">
                            <Link to="#" className="banner-btn">
                                <Skeleton height={800} />
                            </Link>
                        </div>
                    ))}
                </OwlCarousel>
            ) : (
                <OwlCarousel className="banner-carousel owl-carousel owl-theme" {...settings}>
                    {bannerImage.map((banner, index) => (
                        <div key={index} className="item" 
                        // onClick={() => handleBannerClick(banner.type_id, banner.type)}
                        >
                            <Link to="#" className="banner-btn">
                                <img
                                    src={banner.media_url || defaultImage}
                                    alt={banner.banner_title}
                                    onError={(e) => (e.target.src = defaultImage)}
                                />
                            </Link>
                            <div className="container">
                                <div className="content">
                                </div>
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            )}
        </section>
    );
};

export default WeddingStore;
