// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.visit-store-digitally {
    padding: 20px;
}

.carousel-section {
    margin-bottom: 20px;
}

.carousel-slide {
    position: relative;
}

.carousel-image {
    width: 100%;
    height: auto;
}

.carousel-caption {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
}

.stores-section {
    margin-top: 20px;
}

.stores-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.store-card {
    width: calc(33.333% - 20px);
    box-sizing: border-box;
}

.store-image {
    width: 100%;
    height: auto;
}

.store-info {
    text-align: center;
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/home/VisitStoreDigitally.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,oCAAoC;IACpC,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,SAAS;AACb;;AAEA;IACI,2BAA2B;IAC3B,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":[".visit-store-digitally {\n    padding: 20px;\n}\n\n.carousel-section {\n    margin-bottom: 20px;\n}\n\n.carousel-slide {\n    position: relative;\n}\n\n.carousel-image {\n    width: 100%;\n    height: auto;\n}\n\n.carousel-caption {\n    position: absolute;\n    bottom: 20px;\n    left: 20px;\n    color: white;\n    background-color: rgba(0, 0, 0, 0.5);\n    padding: 10px;\n}\n\n.stores-section {\n    margin-top: 20px;\n}\n\n.stores-grid {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 20px;\n}\n\n.store-card {\n    width: calc(33.333% - 20px);\n    box-sizing: border-box;\n}\n\n.store-image {\n    width: 100%;\n    height: auto;\n}\n\n.store-info {\n    text-align: center;\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
