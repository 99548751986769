// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/WeddingStore.css */
.wedding-store {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.slide {
    position: relative;
}

.wedding-image {
    width: 100%;
    height: auto;
}

.caption {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/home/WeddingStore.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,gBAAgB;IAChB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,2BAA2B;IAC3B,oCAAoC;IACpC,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB","sourcesContent":["/* src/components/WeddingStore.css */\n.wedding-store {\n    max-width: 800px;\n    margin: 0 auto;\n    text-align: center;\n}\n\n.slide {\n    position: relative;\n}\n\n.wedding-image {\n    width: 100%;\n    height: auto;\n}\n\n.caption {\n    position: absolute;\n    bottom: 20px;\n    left: 50%;\n    transform: translateX(-50%);\n    background-color: rgba(0, 0, 0, 0.5);\n    color: white;\n    padding: 10px;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
