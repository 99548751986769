import React, { useState, useEffect, useRef } from 'react';
import { useParams,useLocation, useNavigate  } from 'react-router-dom';
import api from '../../api';

const NavMenu = () => {
    const { category_id, gender_id, segment_id, sub_category_id } = useParams();
    
        console.log(category_id,'category_id');
        console.log(sub_category_id,'sub_category_id');
    const [menuData, setMenuData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeCategory, setActiveCategory] = useState('');
    const [activeSubCategory, setActiveSubCategory] = useState('');
    const [hoveredCategory, setHoveredCategory] = useState(null);
    const [showShopMenu, setShowShopMenu] = useState(false);
    const [showStylesMenu, setShowStylesMenu] = useState(false);
    const location = useLocation();
    const megaMenuRef = useRef(null);
    const navigate = useNavigate();
    const [selectedSubCategory, setSelectedSubCategory] = useState(activeSubCategory);
    const [selectedCategory, setSelectedCategory] = useState(activeCategory);
    // Fetch categories
    useEffect(() => {
        const api_token = localStorage.getItem('apiToken');
        const fetchCategories = async () => {
            try {
                const response = await api.get('/website/get_nav_menu', {
                    params: {
                        api_token,
                        gender_id: 2,
                        segment_id: 1,
                        menu_order: 6,
                    },
                });

                if (response.status !== 200) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                setMenuData(response.data.data || {});
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        console.log(api_token);
  if(api_token!=null)
    {

        fetchCategories();
    }   

    }, []);

    // Update active category based on the current route
    useEffect(() => {
        const path = location.pathname;
        let activeCat = '';

        Object.keys(menuData).forEach((mainItem) => {
            const item = menuData[mainItem];
            if (
                (item.type === 'segment' && path.includes(item.id)) ||
                (item.type === 'deal' && path === '/deals') ||
                (item.type === 'livesocial' && path === '/live-social') ||
                (item.type === 'chat' && path === '/chat') ||
                (item.type === 'storelisting' && path === '/stores')
            ) {
                activeCat = mainItem;
            }
        });

        if (activeCat && activeCat !== 'Shop' && activeCat !== 'Styles') {
            setActiveCategory(activeCat);
        }
    }, [location.pathname, menuData]);

    // Update active category and sub-category based on the URL parameters
    useEffect(() => {
        setActiveCategory(category_id || '');
        setActiveSubCategory(sub_category_id || '');
    }, [category_id, sub_category_id]);

    // Close menus when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (megaMenuRef.current && !megaMenuRef.current.contains(event.target)) {
                setHoveredCategory(null);
                setShowShopMenu(false);
                setShowStylesMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Handle hover for categories except "Shop" and "Styles"
    const handleMouseEnterCategory = (mainItem) => {
        if (mainItem !== 'Shop' && mainItem !== 'Styles') {
            setHoveredCategory(mainItem);
        }
    };

    const handleMouseLeaveCategory = () => {
        setHoveredCategory(null);
    };

    // Handle hover for "Shop" and "Styles"
    const handleMouseEnterShop = () => {
        setShowStylesMenu(false);
        setShowShopMenu(true);
    };

    const handleMouseEnterStyles = () => {
        setShowShopMenu(false);
        setShowStylesMenu(true);
    };

    const handleMouseLeaveMenus = () => {
        setShowShopMenu(false);
        setShowStylesMenu(false);
    };

    // Handle navigation
    const handleNavigation = (mainItem, subCategoryId, path) => {
        setShowShopMenu(false);
        setShowStylesMenu(false);
        setHoveredCategory(null);

        setActiveCategory(mainItem);
        setActiveSubCategory(subCategoryId);
        setHoveredCategory(null);
        navigate(path, { state: { mainItem, subCategoryId } });

    // Refresh the page
    // window.location.reload();
    };
  // Update selected sub-category based on the URL parameters
  useEffect(() => {
    setSelectedSubCategory(sub_category_id || '');
    setSelectedCategory(category_id || '');
    console.log(sub_category_id,'useEffect');
}, [category_id,sub_category_id]);
console.log(selectedSubCategory,'category_id');
console.log(selectedSubCategory,'selectedSubCategory');
    return (
        <ul className="nav-menu">
            {Object.keys(menuData)
                .sort((a, b) => menuData[a].menu_order - menuData[b].menu_order)
                .map((mainItem) => {
                    const item = menuData[mainItem];
                    const isCategoryActive = mainItem === activeCategory;
                    const isShopActive = mainItem === 'Shop' && showShopMenu;
                    const isStylesActive = mainItem === 'Styles' && showStylesMenu;

                    return (
                        <li key={item.id} className={`menuItemClass ${mainItem} ${isCategoryActive ? 'active' : ''}`}>
                            <a
                                onClick={() => handleNavigation(
                                    mainItem,
                                    '',
                                    item.type === 'category'
                                        ? `/shop/${item.id}/${item.gender_id}/${item.segment_id}`
                                        : item.type === 'deal'
                                        ? '/deals'
                                        : item.type === 'livesocial'
                                        ? '/live-social'
                                        : item.type === 'styles'
                                        ? '#'
                                        : item.type === 'storelisting'
                                        ? '/stores'
                                        : '/'
                                )}
                                onMouseEnter={() => mainItem === 'Shop' ? handleMouseEnterShop() : mainItem === 'Styles' ? handleMouseEnterStyles() : handleMouseEnterCategory(mainItem)}
                            >
                                {mainItem}
                            </a>

                            {/* Sub-categories for categories except "Shop" and "Styles" */}
                            {hoveredCategory === mainItem && item.sub_category_list && (
                                <div
                                    className="mega-menu-trigger mega-menu"
                                    onMouseEnter={() => setHoveredCategory(mainItem)}
                                    onMouseLeave={handleMouseLeaveCategory}
                                >
                                    <div ref={megaMenuRef} className="sub-category-menu">
                                        <div className="container">
                                            <div className="row">
                                                {item.sub_category_list.map((subCategory) => (
                                                    <div key={subCategory.sub_category_id} className={`col-3 menuItemClassSub-data ${activeSubCategory === subCategory.sub_category_id ? 'active' : ''   || selectedSubCategory=== subCategory.sub_category_id ? 'active' : ''}`}>
                                                        <a onClick={() => handleNavigation(mainItem, subCategory.sub_category_id, `/shop/${subCategory.category_id}/${subCategory.gender_id}/${subCategory.segment_id}/${subCategory.sub_category_id}`)}>
                                                            {subCategory.sub_category_name}
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Shop menu */}
                            {mainItem === 'Shop' && isShopActive && (
                                <div
                                    className="mega-menu-trigger mega-menu"
                                    onMouseEnter={handleMouseEnterShop}
                                    onMouseLeave={handleMouseLeaveMenus}
                                >
                                    <div ref={megaMenuRef} className="sub-category-menu">
                                        <div className="container">
                                            <div className="row">
                                                {item.categories_list.map((category) => (
                                                    <div key={category.category_id} className={`col-3 menuItemClassSub-data ${activeSubCategory === category.category_id ? 'active' : ''}`}>
                                                        <a onClick={() => handleNavigation(mainItem, category.category_id, `/shop/${category.category_id}/${category.gender_id}/${category.segment_id}`)}>
                                                            {category.name}
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Styles menu */}
                            {mainItem === 'Styles' && isStylesActive && (
                                <div
                                    className="mega-menu-trigger mega-menu"
                                    onMouseEnter={handleMouseEnterStyles}
                                    onMouseLeave={handleMouseLeaveMenus}
                                >
                                    <div ref={megaMenuRef} className="sub-category-menu">
                                        <div className="container">
                                            <div className="row">
                                                {item.sidebar.map((sidebars) => (
                                                    <div key={sidebars.tag_id} className={`col-3 menuItemClassSub-data ${activeSubCategory === sidebars.tag_id ? 'active' : ''}`}>
                                                        <a onClick={() => handleNavigation(mainItem, sidebars.tag_id, `/latest-arrivals/${sidebars.tag_id}/${sidebars.type}`)}>
                                                            {sidebars.name}
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </li>
                    );
                })}
        </ul>
    );
};

export default NavMenu;
