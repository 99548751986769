import React, { createContext, useState, useEffect } from 'react';
import api from '../api';
import { useAuth } from '../context/AuthContext';

const CheckoutContext = createContext();

const CheckoutProvider = ({ children }) => {
    const { logout } = useAuth();

    const [existingAddress, setExistingAddress] = useState([]);
    const [currency, setCurrency] = useState(null);
    const [symbol, setSymbol] = useState('');
    const [remaining, setRemaining] = useState(0);
    const [couponType, setCouponType] = useState('');
    const [shippingData, setShippingData] = useState([]);
    const [shipObjectId, setShipObjectId] = useState('');
    const [shipLocalAmount, setShipLocalAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalIncludeShipping, setTotalIncludeShipping] = useState(0);
    const [showShipping, setShowShipping] = useState(false);

    // Get values from localStorage
    const api_token = localStorage.getItem('apiToken');
    const cart_id = localStorage.getItem('cart_id');
    const order_num = localStorage.getItem('order_num');
    const isValidApiToken = () => api_token && api_token !== 'null' && api_token !== 'undefined' && api_token.trim() !== '';

    const getAddress = async () => {
        if (!isValidApiToken()) return; 

        try {
            const response = await api.get(`/user/get_user_address_list?&api_token=${api_token}`);
            setExistingAddress(response.data);
            const data = response.data;

            if (data?.user_address?.length > 0) {
                const existing = data.user_address[0] || {};
                if (existing.address_id) {
                    calculateCredit();
                }
            } else if (response.status === 401) {
                logout();
            }
        } catch (error) {
            console.log(error, "address error");
        }
    };

    const addAddress = async (data) => {
        if (!isValidApiToken()) return; 

        const formattedData = {
            country_code: "+1",
            api_token: api_token,
            type: "home",
            ...data,
        };
        try {
            const response = await api.post(`/user/add_address`, formattedData);
            getAddress();
        } catch (error) {
            console.log(error, "address error");
        }
    };

    const calculateCredit = async () => {
        if (!isValidApiToken() || !order_num) return; 

        try {
            const response = await api.get(`/credits/calculate_credits?&api_token=${api_token}&order_num=${order_num}&coupon_id=0`);

            if (response.data.status === 200) {
                const calculateCreditsResponse = response.data;

                // Set currency and symbol
                if (calculateCreditsResponse.currency_code && calculateCreditsResponse.currency_code.length > 0) {
                    setCurrency(calculateCreditsResponse.currency_code);
                    setSymbol(calculateCreditsResponse.currency_symbol);
                }

                // Set remaining credits
                if (calculateCreditsResponse.remaining && calculateCreditsResponse.remaining.length > 0) {
                    setRemaining(calculateCreditsResponse.remaining);
                }
                
                // Set coupon type
                if (calculateCreditsResponse.coupon_type && calculateCreditsResponse.coupon_type.length > 0) {
                    setCouponType(calculateCreditsResponse.coupon_type);
                }

                // Handle shipping data
                if (calculateCreditsResponse.shipping?.data && calculateCreditsResponse.shipping.data.length > 0) {
                    setShowShipping(true);
                    setShippingData(calculateCreditsResponse.shipping.data);
                    setShipObjectId(calculateCreditsResponse.shipping.internal_ship_ref);
                    setShipLocalAmount(parseFloat(calculateCreditsResponse.shipping.data[0].amount_local));
                    setTotalAmount(parseInt(calculateCreditsResponse.total_cart_value, 10));
                } else {
                    setShowShipping(false);
                    setTotalAmount(parseInt(calculateCreditsResponse.total_cart_value, 10));
                }

                // Calculate total amount including shipping
                const totalShippingAmount = parseInt(calculateCreditsResponse.total_cart_value, 10) + (parseFloat(shipLocalAmount));
                setTotalIncludeShipping(totalShippingAmount);
            }
        } catch (error) {
            console.log(error, "credits calculation error");
        }
    };

    useEffect(() => {
        if (isValidApiToken()) { // Only call `getAddress` if `api_token` is valid
            getAddress();
        }
    }, [api_token]);

    return (
        <CheckoutContext.Provider value={{
            existingAddress,
            addAddress,
            calculateCredit,
            currency,
            symbol,
            remaining,
            couponType,
            shippingData,
            shipObjectId,
            shipLocalAmount,
            totalAmount,
            totalIncludeShipping,
            showShipping
        }}>
            {children}
        </CheckoutContext.Provider>
    );
};

export { CheckoutContext, CheckoutProvider };
