// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LatestArrivals.css */
.latest-arrivals {
    width: 100%;
    max-width: 100%;
}

.product-slide {
    position: relative;
    text-align: center;
}

.product-image {
    width: 100%;
    height: auto;
}

.product-info {
    padding: 10px;
    background-color: #fff;
}

.product-title {
    font-size: 1.2em;
    margin: 0;
}

.product-price {
    font-size: 1.1em;
    color: #333;
}`, "",{"version":3,"sources":["webpack://./src/components/home/LatestArrivals.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf","sourcesContent":["/* LatestArrivals.css */\n.latest-arrivals {\n    width: 100%;\n    max-width: 100%;\n}\n\n.product-slide {\n    position: relative;\n    text-align: center;\n}\n\n.product-image {\n    width: 100%;\n    height: auto;\n}\n\n.product-info {\n    padding: 10px;\n    background-color: #fff;\n}\n\n.product-title {\n    font-size: 1.2em;\n    margin: 0;\n}\n\n.product-price {\n    font-size: 1.1em;\n    color: #333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
