// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Center the modal on the screen */
.video-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    /* Ensure it's above other elements */
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    /* Scrollable if content is too long */
}

/* Style the overlay to cover the entire screen */
.video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Dark semi-transparent background */
    z-index: 1000;
    /* Ensure it's below the modal */
}

/* Style the close button */
.video-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #000;
    transition: color 0.3s;
}

.video-modal-close-button:hover {
    color: #f00;
}

/* Style the video element */
.video-modal-video {
    width: 100%;
    height: auto;
    border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/home/LiveSocial.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,qCAAqC;IACrC,cAAc;IACd,eAAe;IACf,cAAc;IACd,sCAAsC;AAC1C;;AAEA,iDAAiD;AACjD;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,qCAAqC;IACrC,aAAa;IACb,gCAAgC;AACpC;;AAEA,2BAA2B;AAC3B;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf;;AAEA,4BAA4B;AAC5B;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":["/* Center the modal on the screen */\n.video-modal {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: white;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    z-index: 1001;\n    /* Ensure it's above other elements */\n    max-width: 90%;\n    max-height: 90%;\n    overflow: auto;\n    /* Scrollable if content is too long */\n}\n\n/* Style the overlay to cover the entire screen */\n.video-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    /* Dark semi-transparent background */\n    z-index: 1000;\n    /* Ensure it's below the modal */\n}\n\n/* Style the close button */\n.video-modal-close-button {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background: none;\n    border: none;\n    font-size: 16px;\n    cursor: pointer;\n    color: #000;\n    transition: color 0.3s;\n}\n\n.video-modal-close-button:hover {\n    color: #f00;\n}\n\n/* Style the video element */\n.video-modal-video {\n    width: 100%;\n    height: auto;\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
