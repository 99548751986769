import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';
import './Modal.css';

const Modal = ({ isOpen, onClose, videoUrl }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (isOpen && videoUrl && videoRef.current) {
            if (Hls.isSupported() && videoUrl.includes('.m3u8')) {
                const hls = new Hls();
                hls.loadSource(videoUrl);
                hls.attachMedia(videoRef.current);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    videoRef.current.play().catch((err) => {
                        console.error('Error playing HLS stream:', err);
                    });
                });

                return () => {
                    hls.destroy();
                };
            } else {
                videoRef.current.src = videoUrl;
                videoRef.current.play().catch((err) => {
                    console.error('Error playing video:', err);
                });
            }
        }
    }, [isOpen, videoUrl]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay video-frame" onClick={onClose}>
            <div className="modal-content video-play-data" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>×</button>
                <video ref={videoRef} controls autoPlay />
            </div>
        </div>
    );
};

export default Modal;
