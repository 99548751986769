// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Banner.css */

.banner {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.slide {
    position: relative;
}

.banner-image {
    width: 100%;
    height: 661px;
    /* Set a fixed height for the banner */
    object-fit: cover;
    /* Ensure the image covers the entire area */
}

.caption {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.2em;
}`, "",{"version":3,"sources":["webpack://./src/components/home/Banner.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;IACI,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sCAAsC;IACtC,iBAAiB;IACjB,4CAA4C;AAChD;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,oCAAoC;IACpC,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":["/* src/components/Banner.css */\n\n.banner {\n    width: 100%;\n    margin: 0 auto;\n    position: relative;\n    overflow: hidden;\n}\n\n.slide {\n    position: relative;\n}\n\n.banner-image {\n    width: 100%;\n    height: 661px;\n    /* Set a fixed height for the banner */\n    object-fit: cover;\n    /* Ensure the image covers the entire area */\n}\n\n.caption {\n    position: absolute;\n    bottom: 20px;\n    left: 20px;\n    color: white;\n    background-color: rgba(0, 0, 0, 0.5);\n    padding: 10px 20px;\n    border-radius: 5px;\n    font-size: 1.2em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
