import React from 'react';
import { Link ,useLocation} from 'react-router-dom';
const Footer = () => {
    const location = useLocation();  // Get the current location (route)
      // Check if the current path is the homepage "/"
  const isHomePage = location.pathname === '/';

    return (
        <>
            <footer id="new">
                <div className="container">
                    <div className="logo">
                        <Link
                        // to="/"
                        >
                            <img src="/images/topnavi.png" alt="Seashels Logo" />
                        </Link>
                    </div>
                    <div className="ftr-menu">
                        <ul>
                            <li>
                                <Link to="/contact">Contact</Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to="/terms-services">Terms of Services</Link>
                            </li>
                            <li>
                                <Link to="/shipping-returns">Shipping & Returns</Link>
                            </li>
                            <li>
                                <Link to="/how-to-delete-your-account">Seashels App Account Delete Guide</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="ftr-contact">
                        <form action="">
                            <div className="field group">
                                <div className="field-row">
                                    <input type="text" placeholder="Your full name*" required />
                                </div>
                                <div className="field-row">
                                    <input type="email" placeholder="Email *" required />
                                </div>
                            </div>
                            <div className="field">
                                <div className="field-row">
                                    <input type="tel" placeholder="Telephone *" required />
                                </div>
                            </div>
                            <div className="field">
                                <div className="field-row">
                                    <textarea placeholder="Message" required></textarea>
                                </div>
                            </div>
                            <div className="field">
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className="copy-right">
                        <p>© 2024 Fractal Systems. All rights reserved.</p>
                    </div>
                </div>
            </footer>

            <div className="test-model">
            {isHomePage && (
                <div className="content model-popup">
                    <div className="banner-content">
                        <div className="model-text">
                            <a href="#">
                                <img src="/images/topnavi.png" alt="Seashels logo" />
                            </a>
                            <p>
                            Discover latest arrivals in real time, talk to stores directly, watch their live streams and visit them virtually

                            </p>
                            <p> Download the app   </p>
                            <ul className="links">
                                <li>
                                    <a href="https://apps.apple.com/us/app/seashelsapp/id6463077820" target="_blank" rel="noopener noreferrer">
                                   
                                        <img src="/images/app-store.png" alt="App Store" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://play.google.com/store/apps/details?id=com.seashels.discover&amp;pli=1" target="_blank" rel="noopener noreferrer">
                                     
                                        <img src="/images/google-play.png" alt="Google Play Store" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
              )}
            </div>
        </>
    );
};

export default Footer;
