// src/utils/getClientIp.js
import { v4 as uuidv4 } from 'uuid';

export const getClientIp = async () => {
    let clientId = localStorage.getItem('clientId');
    if (!clientId) {
        clientId = uuidv4();  // Using uuid v4 to generate a unique client ID
        localStorage.setItem('clientId', clientId);
    }
    return clientId;

};

