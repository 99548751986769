import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './VisitStoreDigitally.css';
import { Link } from 'react-router-dom';

const VisitStoreDigitally = ({ digital_stores, stores, loading }) => {
    const [view, setView] = useState('carousel'); // Default view

    // Owl Carousel settings
    const carouselSettings = {
        items: 1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        dots: true,
    };

    return (
        <section className='store'>
            <div className="visit-store-digitally">
                {/* <h2>
                    {loading ? <Skeleton width={200} /> : digital_stores.heading}
                    <Link 
                    className="read-more"></Link>
                </h2> */}

                <div className="view-toggle" style={{ display: 'none' }}>
                    <button onClick={() => setView('carousel')} disabled={view === 'carousel'}>
                        Carousel View
                    </button>
                    <button onClick={() => setView('grid')} disabled={view === 'grid'}>
                        Grid View
                    </button>
                </div>

                {view === 'carousel' ? (
                    <div className="carousel-section">
                        {loading ? (
                            <Skeleton height={800} count={3} />
                        ) : (
                            <OwlCarousel className="store-carousel owl-carousel owl-theme" {...carouselSettings}>
                                {digital_stores.carousel.map((banner) => (
                                    <div key={banner.store_uuid} className="item">
                                        {banner.type === 'store_listing' ? (
                                            <Link
                                            //  to="/stores"
                                              className="banner-btn">
                                                <img
                                                    src={banner.profile_image}
                                                    alt={banner.store_name}
                                                    className="carousel-image"
                                                    onError={(e) => (e.target.src = 'https://via.placeholder.com/300')}
                                                />
                                            </Link>
                                        ) : banner.store_uuid ? (
                                            <Link 
                                            // to={`/visit-store/${banner.store_uuid}`} 
                                            className="banner-btn">
                                                <img
                                                    src={banner.profile_image}
                                                    alt={banner.store_name}
                                                    className="carousel-image"
                                                    onError={(e) => (e.target.src = 'https://via.placeholder.com/300')}
                                                />
                                            </Link>
                                        ) : <Link
                                        //  to="/stores" 
                                         className="banner-btn">
                                            <img
                                                src={banner.profile_image}
                                                alt={banner.store_name}
                                                className="carousel-image"
                                                onError={(e) => (e.target.src = 'https://via.placeholder.com/300')}
                                            />
                                        </Link>}

                                    </div>
                                ))}

                            </OwlCarousel>
                        )}
                    </div>
                ) : (
                    'Store Not Found'
                )}
            </div>
        </section>
    );
};

export default VisitStoreDigitally;
